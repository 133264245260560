
/*
* A popup module to handle popups display, aria-attributes and focus management based on defined
* `data-` and `hidden` attributes.
*
* Usage instructions:
*
* 1. Include the module file on your page (preferably in the end)
* 2. Initialize the module with: popup.init();
* 3. Add an `id` to each `flix-popup` element you wish the module to manage
* 4. Connect the target to the popup by passing the popup id to the target's `data-popup` attribute
* 5. Ideally initialize the popup with a hidden attribute on
*/

/*global define*/
/*eslint no-undef: ["error", { "typeof": true }] */

(function (root, factory) {
  if ( typeof define === 'function' && define.amd ) {
    // AMD. Register as an anonymous module.
    define([], factory(root));
  } else if ( typeof exports === 'object' ) {
    module.exports = factory(root);
  } else {
    // Browser globals (root is window)
    root.popup = factory();
  }
})(typeof global !== 'undefined' ? global : this.window || this.global, function () {

  'use strict';

  var popup = {};

  // selector for the target elements that we should apply the module handlers
  var TARGET_SELECTOR = 'data-popup';
  var ACTIVE_MODIFIER = 'flix-popup--active';

  /**
   * Toggles popup based on the current value of `hidden` attribute
   * @private
   */
  var togglePopup = function (target, popupElement) {
    if (popupElement.hasAttribute('hidden')) {
      showPopup(target, popupElement);
    } else {
      hidePopup(target, popupElement);
    }
  };

  /**
   * Shows popup and sets target expanded to true if "click" event
   * @private
   */
  var showPopup = function (target, popupElement) {
    popupElement.removeAttribute('hidden');
    popupElement.classList.add(ACTIVE_MODIFIER);

    var firstFocusableElement = document.getElementById(
      target.getAttribute('data-firstFocusable')
    );
    firstFocusableElement && firstFocusableElement.focus();
  };

  /**
   * Hides popup and sets target expanded to false if "click" event
   * @private
   */
  var hidePopup = function (target, popupElement) {
    popupElement.setAttribute('hidden', '');
    popupElement.classList.remove(ACTIVE_MODIFIER);
    target.focus();
  };

  /**
   * Handles the event listeners
   * @private
   */
  var attachPopupEventHandlers = function (target, popupElement) {
    var closeButton = popupElement.querySelector('[class*="__close"]');
    var firstFocusableId = target.getAttribute('data-firstFocusable');
    var lastFocusableId = target.getAttribute('data-lastFocusable');
    var firstFocusableElement = document.getElementById(firstFocusableId);
    var lastFocusableElement = document.getElementById(lastFocusableId);

    target.addEventListener('click', function() {
      togglePopup(target, popupElement);
    });

    if (closeButton !== null) {
      closeButton.addEventListener('click', function () {
        hidePopup(target, popupElement);
      });
    }

    if (firstFocusableElement === null) {
      /* eslint-disable no-console */
      console.warn('popup.js plugin error: Cannot find element with id ' + firstFocusableId + ' supplied to data-firstFocusable attribute.');
    } else {
      firstFocusableElement.addEventListener('keydown', function (event) {
        if (event.shiftKey && (event.key === 'Tab' || event.keyCode === 9)) {
          event.preventDefault();
          lastFocusableElement && lastFocusableElement.focus();
        }
      });
    }

    if (lastFocusableElement === null) {
      /* eslint-disable no-console */
      console.warn('popup.js plugin error: Cannot find element with id ' + lastFocusableId + ' supplied to data-lastFocusable attribute.');
    } else {
      lastFocusableElement.addEventListener('keydown', function (event) {
        if (!event.shiftKey && (event.key === 'Tab' || event.keyCode === 9)) {
          event.preventDefault();
          firstFocusableElement.focus();
        }
      });
    }

    popupElement.addEventListener('keydown', function (event) {
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        hidePopup(target, popupElement);
      }
    });
  };

  /**
   * Initialize the plugin
   */
  popup.init = function () {
    var popupTargets = document.querySelectorAll('['+ TARGET_SELECTOR +']');

    popupTargets.forEach(function (target) {
      var popupId = target.getAttribute(TARGET_SELECTOR);
      var popupElement = document.getElementById(popupId);

      if (popupElement === null) {
        /* eslint-disable no-console */
        console.warn('popup.js plugin error: Cannot find element with id ' + popupId + ' for target ' + target);
      } else {
        popupElement.setAttribute('role', 'dialog');
        popupElement.setAttribute('aria-modal', 'true');
        attachPopupEventHandlers(target, popupElement);
      }
    });
  };

  return popup;
});
